import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import { HTMLContent } from "../components/common/Content";
import Layout from "../components/layout/Layout";
import highlightCode from "../components/utils/HighlightCode";

// props
interface Props {
  data: {
    guide: {
      html: any;
      frontmatter: {
        title: string;
        description?: string;
        keywords?: Array<string>;
        githubUrl?: string;
      };
    };
  };
}

// markup
const GuideTemplate = (props: Props) => {
  useEffect(() => {
    highlightCode();
  });
  const guide = props.data.guide;

  return (
    <Layout
      className="guide"
      seo={{
        title: `${guide.frontmatter.title}`,
        description: guide.frontmatter.description,
      }}
    >
      <div className="container py-6">
        <div className="pb-5">
          <Link
            to={"/guides"}
            className="button is-white is-white-spring is-button-back"
          >
            <FontAwesomeIcon className="icon" icon={faAngleLeft} />
            All guides
          </Link>
        </div>
        <div className="columns is-space-between">
          <div className="column is-9">
            <div className="ascii-doc">
              <h1 className="title">{guide.frontmatter.title}</h1>
              <HTMLContent content={guide.html}></HTMLContent>
            </div>
          </div>
          <div className="column is-3">
            <div className="has-background-light px-5 py-5 mb-4">
              <h2 className="is-size-4 has-text-weight-bold">Get the Code</h2>
              <div className="container pt-3 has-text-weight-bold">
                <a href={guide.frontmatter?.githubUrl}>
                  <FontAwesomeIcon
                    className="ml-1 mr-3 is-size-5"
                    icon={faGithub}
                    height="20"
                  />
                  Go To Repo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GuideTemplate;

// graphQL Guide query
export const pageQuery = graphql`
  query IndexPage($id: String!) {
    guide: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        githubUrl
      }
    }
  }
`;
